
body {
  font-family: "Poppins", sans-serif;
  /*letter-spacing: 1px;*/
}

.btn-start, .btn-next {
  color: #fff;
  background-color: #004a80;
  border-radius: 5px;
  font-size: 18px !important;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  border: 0
}

.btn-start:hover, .btn-next:hover {
  background-color: #004a80;
}

.btn-back{
  color: #fff;
  background-color: #666;
  border-radius: 5px;
  font-size: 18px !important;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  border: 0
}

.btn-back:hover {
  background-color: #666;
  border: 0
}

.btn-ty {
  color: #fff !important;
text-decoration: none !important;
}

form.list-assessment {
  border: 1px solid #89CCF6;
padding: 40px 20px;
margin-top: 20px;
}

.progress {
    height: 2rem;
    background: #ddd
}

.bg-success {
    background-color: #89CCF6 !important;
}
.badge-info {
    color: #333;
    background-color: #ddd;
    font-weight: normal;
}

.alert-danger {
    color: #ff0000;
    background-color: #fff;
    border: none;
    text-align: center;
}
